import { Injectable } from '@angular/core';
import { AsyncValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { RequestService } from '@services/request.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DriverLicenseVerifyService {
  constructor(
    private request: RequestService,
  ) {
  }

  public verify(state: string, license: string): Observable<any> {
    if (license?.includes('**')) {
      // no need to verify if the license number is masked
      return new Observable((observer) => {
        observer.next({valid: true, status: 'masked'});
        observer.complete();
      });
    }
    const url = `${environment.internal_api_url}/drivers_license`;
    return this.request.send('get', url, {state: state, license: license });
  }
}
