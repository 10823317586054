import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Observable, timer as observableTimer } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { DriverLicenseVerifyService } from '@services/driver-license-verify.service';

@Injectable()
export class DriverLicenseAsyncValidatorService {

  private debounceTime: number = 500;
  public dlvalidate: () => Promise<ValidationErrors | null> | Observable<ValidationErrors | null>;

  constructor(
    private driverLicenseVerifyService: DriverLicenseVerifyService,
  ) {
    this.validate = this.validate.bind(this);
    this.dlvalidate = this.dlValidateFn.bind(this);
  }

  public dlValidateFn(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    try {
      const formGroup = c as UntypedFormGroup;
      const state = formGroup.get('drivingInfo').get('driversLicenseState') as UntypedFormControl;
      const license = formGroup.get('drivingInfo').get('driversLicenseNumber') as UntypedFormControl;
      if (state.value && license.value) {
        // if a new value is sent for checking, the previous Observable will get unsubscribed
        return observableTimer(this.debounceTime).pipe(switchMap(() =>
          this.driverLicenseVerifyService.verify(state.value, license.value).pipe(
            map((result: { status: string, error?: string }) => {
              // create error if result is invalid
              const validation = (result.status === 'invalid' ? {
                message: 'is invalid',
              } as ValidationErrors : null);

              // set error to FormGroup
              if (validation !== null) {
                formGroup.get('drivingInfo').get('driversLicenseNumber').setErrors(validation);
              }
              return validation;
            }))));
      } else {
        return new Promise((resolve: any): void => {
          resolve(null);
        });
      }
    } catch (error) {
      return new Promise((resolve: any): void => {
        resolve({ invalid: true });
      });
    }

  }

  public validate(formGroup: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const state = formGroup.get('driversLicenseState') as UntypedFormControl;
    const license = formGroup.get('driversLicenseNumber') as UntypedFormControl;

    if (state.value && license.value) {
      // if a new value is sent for checking, the previous Observable will get unsubscribed
      return observableTimer(this.debounceTime).pipe(switchMap(() =>
        this.driverLicenseVerifyService.verify(state.value, license.value).pipe(
          map((result: { status: string, error?: string }) => {
            // create error if result is invalid
            const validation = (result.status === 'invalid' ? {
              invalid: {
                message: result.error,
              },
            } as ValidationErrors : null);

            // set error to FormGroup
            return validation;
          }))));
    } else {
      return new Promise((resolve: any): void => {
        resolve(null);
      });
    }
  }

}
