import {
  AbstractControl,
  ValidationErrors,
  FormGroup, ValidatorFn,
} from '@angular/forms';
// @ts-ignore
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

// Extend dayjs with the necessary plugins
dayjs.extend(utc);

export function parseDate(value: string | number): {
  day: string;
  month: string;
  year: string;
  apiFormatted: string;
  formatted: string;
  time: string;
  unix: number;
} {
  try {
    const dayjsDate = (typeof value === 'number') ? dayjs.unix(value) : dayjs(value);
    const unix = dayjsDate.unix();

    return {
      day: dayjsDate.format('DD'),
      month: dayjsDate.format('MM'),
      year: dayjsDate.format('YYYY'),
      apiFormatted: dayjsDate.format('YYYY-MM-DD'),
      formatted: dayjsDate.format('MM/DD/YYYY'),
      time: dayjsDate.format('hh:mm a'),
      unix: unix,
    };
  } catch (e) {
    console.warn(e);
    return {
      day: '',
      month: '',
      year: '',
      apiFormatted: '',
      formatted: '',
      time: '',
      unix: 0,
    };
  }
}

export const CommonValidators = {
  name: (c: AbstractControl) => {
    if (!c.value) { return null; }

    const chars = /^[a-zA-Z][a-zA-Z\s]*$/.test(c.value);
    return chars ? null :
      {
        format: true,
        message: 'can only contain letters',
      } as ValidationErrors;
  },

  fullName: (c: AbstractControl) => {
    if (!c.value) { return null; }

    const chars = /^[a-zA-Z][a-zA-Z\s]*$/.test(c.value);
    return chars ? null : {
      format: true,
      message: 'can only contain letters',
    } as ValidationErrors;
  },

  confirmPassword: (form: FormGroup) =>
    // tslint:disable-next-line:max-line-length
    form.controls.password.value === form.controls.confirmPassword.value ? null : { coincidencePassword: false, message: 'Passwords must match' } as ValidationErrors,

  maxLicenseNumberLength: (max: number): any => (c: AbstractControl): ValidationErrors | null => {
    if (c.value) {
      const licenseValue = c.value.toUpperCase().replace(/[-\s]/g, '');
      const validator = licenseValue.length > max ? {
        format: true,
        message: `should be maximum of ${max} characters`,
      } as ValidationErrors : null;

      return validator;
    }
  },
  /**
   * Checks if field has space symbols in
   */
  hasSpaces(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      if (!value) {
        return null;
      }

      if (/[\s]/g.test(value) === true) {
        return {
          format: true,
          message: 'should not contain spaces',
        };
      }

      return null;
    };
  },
};
