export const environment = {
  production: true,
  envName: 'prod',
  app_domain: 'http://admin.squeeze.com',
  offersApiUrl: 'https://data-api.squeeze.com/api/v1/admin',
  internal_api_url: 'https://internal-api.squeeze.com/api/v1',
  raters_api_url: 'https://squeeze-raters-staging.herokuapp.com/api/v1',
  partners_url: 'https://partners.squeeze.com/',
  ezlynx_url: 'https://app.ezlynx.com/',
  auto_logos_api_url: 'https://squeeze-auto-logo-final.s3.us-east-2.amazonaws.com/',
};
